<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Pedido - Configuración</h4>
            <div class="small text-muted">Configuracón del módulo</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-row>
          <b-col>      

            <b-tabs v-model="tabIndex" card>
              <b-tab title="Mi Empresa" :title-link-class="linkClass(0)">
                  <tab-business></tab-business>
              </b-tab>

              <b-tab title="Vistas" :title-link-class="linkClass(1)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Vistas
                    </h5>                      
                  </div>
                  <b-row>
                    <b-col md="6" sm="12" v-if="crud.form['ID15']">   
                      <b-form-group label="Visualización de la vista de pedidos">       
                        <b-form-select v-model="crud.form.ID15.value_string" :options="crud.form.ID15.value_string_opt"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                      
                  <b-row>
                    <b-col md="12">                          
                      <b-button variant="dark" size="sm" @click="saveRender()">Guardar</b-button>                        
                    </b-col>
                  </b-row>                  
                </b-card-text>
              </b-tab>

              <b-tab title="Notificaciones" :title-link-class="linkClass(2)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Notificaciones
                    </h5>                      
                  </div>

                  <b-row>
                    <b-col md="6" sm="12" v-if="crud.form['ID1']">
                      <b-row>                                                
                        <b-col md="12" sm="12" v-if="crud.form['ID1']">
                          <b-form-group label="Nuevo pedido desde portal web">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.form.ID1.value_string"                               
                                          placeholder="Email destino">
                            </b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col md="12" sm="12" v-if="crud.form['ID6']">
                          <b-form-group label="Renderizado Email">
                              <b-form-checkbox                  
                                v-model="crud.form.ID6.value_boolean"
                                switch
                              >
                                ¿Mostrar imágenes de los productos en las notificaciones por email?
                              </b-form-checkbox>
                              
                          </b-form-group>
                        </b-col>                          

                        <b-col md="12" sm="12" v-if="crud.form['ID7']">
                          <b-form-group>
                              <b-form-checkbox                  
                                v-model="crud.form.ID7.value_boolean"
                                switch
                              >
                                ¿Mostrar observaciones de entrega en las notificaciones por email?
                              </b-form-checkbox>
                          </b-form-group>
                        </b-col>         

                        <b-col md="12" sm="12" v-if="crud.form['ID8']">
                          <b-form-group>
                              <b-form-checkbox                  
                                v-model="crud.form.ID8.value_boolean"
                                switch
                              >
                                ¿Mostrar cálculo de IVA en las notificaciones por email?
                              </b-form-checkbox>
                          </b-form-group>
                        </b-col>      

                        <b-col md="12" v-if="crud.form['ID25']">
                          <b-form-group label="Texto pedido Mayorista" description="Texto que se incluye en los emails de notificación para pedidos mayoristas">
                            <b-form-textarea
                              size="sm"                              
                              v-model="crud.form.ID25.value_string"
                              placeholder="Ingrese un texto ..."
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>         

                        <b-col md="12" v-if="crud.form['ID26']">
                          <b-form-group label="Texto pedido Minorista" description="Texto que se incluye en los emails de notificación para pedidos minoristas">
                            <b-form-textarea
                              size="sm"                              
                              v-model="crud.form.ID26.value_string"
                              placeholder="Ingrese un texto ..."
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>                                                   
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="saveNotification()">Guardar</b-button>                        
                        </b-col>
                      </b-row>
                      
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
              
              <b-tab title="Clientes" :title-link-class="linkClass(3)" :disabled="!showTabCustomer">
                <b-card-text>
                  <div v-if="isModuleBCRAActive">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-3">
                        Situación Crediticia
                      </h5>                      
                    </div>

                    <b-row>
                      <b-col md="6" sm="12" v-if="crud.form['ID2']">
                        <b-row>                                                
                          <b-col md="8">
                            <b-form-group label="Valor máximo permitido para aceptar pedidos">
                              <b-form-input type="number"
                                            step="1"
                                            size="sm"
                                            v-model="crud.form.ID2.value_number"
                                            placeholder="Valor de situación">
                              </b-form-input>
                            </b-form-group>
                          </b-col>   
                          <b-col md="8" sm="8" v-if="crud.form['ID16']">
                            <b-form-group>
                                <b-form-checkbox                  
                                  v-model="crud.form.ID16.value_boolean"
                                  switch
                                >
                                  ¿Cancelar pedidos emitidos de clientes con situación crediticia mayor?
                                </b-form-checkbox>
                                
                            </b-form-group>
                          </b-col>                                                  
                        </b-row>
                        
                        <b-row>
                          <b-col md="12">                          
                            <b-button variant="dark" size="sm" @click="saveSituationBCRA()">Guardar</b-button>                        
                          </b-col>
                        </b-row>
                        
                      </b-col>
                    </b-row>
                  </div>
                </b-card-text>                
              </b-tab>

              <b-tab title="Productos" :title-link-class="linkClass(4)">
                <b-card-text>
                  <div>
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-3">
                        Productos
                      </h5>                      
                    </div>

                    <b-row>
                      <b-col md="4" v-if="crud.form['ID11']">
                        <b-form-group label="Máximo de items por pedido" description="Si el valor es 0 (cero), no se tiene en cuenta el limite">
                          <b-form-input type="number"
                                        step="1"
                                        size="sm"
                                        v-model="crud.form.ID11.value_number"
                                        placeholder="Items por pedido">
                          </b-form-input>
                        </b-form-group>
                      </b-col>                        
                    

                      <b-col md="12" sm="12" v-if="crud.form['ID3']">
                        <b-form-group label="Stock">
                            <b-form-checkbox                  
                              v-model="crud.form.ID3.value_boolean"
                              switch
                            >
                              ¿Permite agregar items sin stock?
                            </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" sm="12" v-if="crud.form['ID4']">
                        <b-form-group label="Precio">
                            <b-form-checkbox                  
                              v-model="crud.form.ID4.value_boolean"
                              switch
                            >
                              ¿Permite editar el precio del producto?
                            </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" sm="12" v-if="crud.form['ID17']">
                        <b-form-group label="Duplicados" description="Sobrescribe el parámetro general del módulo">
                            <b-form-checkbox                  
                              v-model="crud.form.ID17.value_boolean"
                              switch
                            >
                              ¿Permite agregar productos duplicados?
                            </b-form-checkbox>
                        </b-form-group>
                        <b-alert variant="info" show v-if="!crud.form.ID17.value_boolean">
                          <i>
                            Si esta opción esta desactivada, se utiliza el parámetro general <u>ID: 52</u> <br>
                            <u>Estado actual</u> <b>{{this.parameters.multipleItems}}</b>
                          </i>
                        </b-alert>
                      </b-col>                            
                    </b-row>

                    <b-row>
                      <b-col md="12">                          
                        <b-button variant="dark" size="sm" @click="saveProducts()">Guardar</b-button>                        
                      </b-col>
                    </b-row>
                                        
                  </div>
                </b-card-text>  
              </b-tab>

              <b-tab title="Integraciones" :title-link-class="linkClass(5)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Integraciones
                    </h5>                      
                  </div>

                  <b-row>
                    <b-col md="6" sm="12" v-if="crud.form['ID5']">
                      <b-row>                                                
                        <b-col md="8">
                          <b-form-group label="URL sistema producción" description="Este proceso sirve para vincular un sistema de producción con la fabricación del pedido">
                            <b-form-input type="url"
                                          size="sm"
                                          v-model="crud.form.ID5.value_string"                               
                                          placeholder="Ingresar la URL">
                            </b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col md="12" sm="12" v-if="crud.form['ID9']">
                          <b-form-group>
                              <b-form-checkbox                  
                                v-model="crud.form.ID9.value_boolean"
                                switch
                              >
                                ¿Enviar el pedido de forma automática al sistema de producción? <i style="font-size:10px;">(tiene un retardo de 90 segundo para procesar el pedido)</i>
                              </b-form-checkbox>
                              
                          </b-form-group>
                        </b-col>                               
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="saveIntegration()">Guardar</b-button>                        
                        </b-col>
                      </b-row>
                      
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>  

              <b-tab title="Estados" :title-link-class="linkClass(6)">
                <b-card-text>
                  
                  <b-row>
                    <b-col md="6">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-3">
                          Estados
                        </h5>                      
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-3">
                          Estados Sistema de Producción
                        </h5>                                    
                      </div>
                    </b-col>
                  </b-row>                                
                  <b-row v-if="crud.form.ID10.value_string_arr">
                    <b-col md="6" sm="12" v-if="crud.form['ID10']">
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_ref[0]"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_arr[0]"                               
                                            placeholder="Estado Alternativo">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_ref[1]"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_arr[1]"                               
                                            placeholder="Estado Alternativo">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_ref[2]"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_arr[2]"                               
                                            placeholder="Estado Alternativo">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_ref[3]"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_arr[3]"                               
                                            placeholder="Estado Alternativo">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_ref[4]"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_arr[4]"                               
                                            placeholder="Estado Alternativo">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_ref[5]"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_arr[5]"                               
                                            placeholder="Estado Alternativo">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_ref[6]"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID10.value_string_arr[6]"                               
                                            placeholder="Estado Alternativo">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>                                                                                                                                                    
                      <b-row>
                        <b-col md="12" sm="12">
                          <hr>
                        </b-col>                        
                        <b-col md="12" sm="12" v-if="crud.form['ID14']">
                          <b-form-group label='Estado "En Proceso"'>
                              <b-form-checkbox                  
                                v-model="crud.form.ID14.value_boolean"
                                switch
                              >
                                ¿Mostrar pedidos con estados "En Proceso"?
                              </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                      </b-row>                      
                    </b-col>
                  
                    <b-col md="6" sm="12" v-if="crud.form['ID12'] && crud.form['ID13']">
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID12.value_string_ref"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID12.value_string"                               
                                            placeholder="Estado (En Cola)">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>
                      <b-row>                                                
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID13.value_string_ref"                               
                                            :disabled="true">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.ID13.value_string"                               
                                            placeholder="Estado (Error">
                              </b-form-input>
                            </b-form-group>                          
                          </b-col>
                      </b-row>   
                    </b-col>               
                  </b-row>

                  <b-row class="mt-3">
                    <b-col md="12">                          
                      <b-button variant="dark" size="sm" @click="saveStatusAlt()">Guardar</b-button>                        
                    </b-col>
                  </b-row>                  
                </b-card-text>                
              </b-tab>

              <b-tab title="Pedidos" :title-link-class="linkClass(7)">
                <b-card-text>                                  
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Pedidos
                    </h5>                      
                  </div>

                  <b-row>
                    <b-col md="12" sm="12" v-if="crud.form['ID24']">
                      <b-form-group label="Ecommerce">
                          <b-form-checkbox                  
                            v-model="crud.form.ID24.value_boolean"
                            switch
                          >
                            Descuenta stock en la generación del pedido
                          </b-form-checkbox>                        
                      </b-form-group>
                    </b-col>

                    <b-col md="12" sm="12" v-if="crud.form['ID18'] && crud.form['ID19']">
                      <b-form-group label="Transporte">

                          <b-form-checkbox                  
                            v-model="crud.form.ID19.value_boolean"
                            switch
                            @change="availableShipping()"
                          >
                            Habilitar el transporte en la carga del pedido
                          </b-form-checkbox>

                          <b-form-checkbox                  
                            v-model="crud.form.ID18.value_boolean"
                            switch
                            @change="requiredShipping()"
                          >
                            El transporte es requerido en la carga del pedido
                          </b-form-checkbox>

                      </b-form-group>
                    </b-col>                          

                    <b-col md="4" sm="12" v-if="crud.form['ID20']">                        
                      <b-form-group label="Descuentos: Etiqueta" description="Etiqueta para Descuentos por defecto.">
                        <b-form-input type="text"
                                      size="sm"                                        
                                      v-model="crud.form.ID20.value_string"                               
                                      placeholder="Etiqueta Descuentos">
                        </b-form-input>                               
                      </b-form-group>
                    </b-col>  

                    <b-col md="4" sm="12" v-if="crud.form['ID21']">                        
                      <b-form-group label="Descuentos: Valores" description="Lista de valores por defectos para para Descuentos. Ej: [5, 15, 20]">
                        <b-form-tags                
                          v-model="crud.form.ID21.value_string"
                          separator=" ,"
                          size="sm"
                          placeholder="Ingrese nuevos correos separadas por enter, espacio o coma"
                        ></b-form-tags>                                                             
                      </b-form-group>
                    </b-col>    

                    <b-col md="4" sm="12" v-if="crud.form['ID22']">                        
                      <b-form-group label="Descuentos: Aplicación">
                        <b-form-checkbox                  
                          v-model="crud.form.ID22.value_boolean"
                          switch
                        >
                          Calcula y muestra el descuento sin descontar del total del pedido
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                  </b-row>

                  <b-row>
                    <b-col md="12">                          
                      <b-button variant="dark" size="sm" @click="saveOrders()">Guardar</b-button>                        
                    </b-col>
                  </b-row>                  
                </b-card-text>
              </b-tab>
            </b-tabs>
            
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Session from '@/handler/session'  
  import TabBusiness from '@/components/inc/myBusiness/business'
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    components: {        
      TabBusiness,
      FindObject,
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.PEDIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configuration',
          elements: {}
        }, 
        parameters: {
          multipleItems: Helper.hasParametersAccess(Param.P52),          
        },               
        crud: { 
          form: {
            ID1: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
            ID2: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
            ID3: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
            ID4: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },    
            ID5: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },   
            ID6: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },   
            ID7: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },   
            ID8: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },   
            ID9: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },     
            ID10: {
              id: 0,
              value_string_ref: ["in_process","closed","in_preparation","pending_shipping","in_transport","delivered","cancel"],
              value_string_arr: [],
              value_string: '',              
              value_number: 0,
              value_boolean: false,
            },
            ID11: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
            ID12: {
              id: 0,
              value_string_ref: 'pending_manufacture',              
              value_string: '',              
              value_number: 0,
              value_boolean: false,
            },
            ID13: {
              id: 0,
              value_string_ref: 'error_manufacture',              
              value_string: '',              
              value_number: 0,
              value_boolean: false,
            },      
            ID14: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },      
            ID15: {
              id: 0,
              value_string: 'both',
              value_string_opt: [
                {value: 'wholesaler', text: 'Mayorista'},
                {value: 'retailer', text: 'Minorista'},
                {value: 'both', text: 'Mayorista y Minorista'}
              ],
              value_number: 0,
              value_boolean: false,
            },    
            ID16: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },       
            ID17: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },     
            ID18: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },   
            ID19: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },    
            ID20: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },                
            ID21: {
              id: 0,
              value_string: [],
              value_number: 0,
              value_boolean: false,
            },
            ID22: {
              id: 0,
              value_string: [],
              value_number: 0,
              value_boolean: false,
            }, 
            ID24: {
              id: 0,
              value_string: [],
              value_number: 0,
              value_boolean: true,
            },   
            ID25: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: true,
            },   
            ID26: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: true,
            },                                                                                          
          },             
        },
        tabIndex: 0,       
        modulesActive: [],        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      this.modulesActive = Session.getSession().auth.user.permissions.modules

      this.show()      
    },
    computed: {
      isModuleBCRAActive() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.BCRA) {
            status = true
          }
        })
        return status
      },   
      showTabCustomer() {
        var show = false
        if(this.isModuleBCRAActive) {
          show = true
        }
        return show
      }
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      },      
      show() {   
        var result = serviceAPI.obtenerConfiguracion()

        result.then((response) => {
          var data = response.data
          
          data.forEach(element => {            
            if(element.id == 1)  {
              this.crud.form.ID1.id = element.id
              this.crud.form.ID1.value_string = element.value_string
            }
            
            if(this.isModuleBCRAActive) {
              if(element.id == 2)  {
                this.crud.form.ID2.id = element.id
                this.crud.form.ID2.value_number = parseInt(element.value_number)
              } 
            }

            if(element.id == 3)  {
              this.crud.form.ID3.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID3.value_boolean = true
              } else {
                this.crud.form.ID3.value_boolean = false
              }
            }                       

            if(element.id == 4)  {
              this.crud.form.ID4.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID4.value_boolean = true
              } else {
                this.crud.form.ID4.value_boolean = false
              }              
            }     

            if(element.id == 5)  {
              this.crud.form.ID5.id = element.id
              this.crud.form.ID5.value_string = element.value_string          
            }     

            if(element.id == 6)  {
              this.crud.form.ID6.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID6.value_boolean = true
              } else {
                this.crud.form.ID6.value_boolean = false
              }   
            }                 

            if(element.id == 7)  {
              this.crud.form.ID7.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID7.value_boolean = true
              } else {
                this.crud.form.ID7.value_boolean = false
              }   
            }     

            if(element.id == 8)  {
              this.crud.form.ID8.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID8.value_boolean = true
              } else {
                this.crud.form.ID8.value_boolean = false
              }   
            }   

            if(element.id == 9)  {
              this.crud.form.ID9.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID9.value_boolean = true
              } else {
                this.crud.form.ID9.value_boolean = false
              }   
            }   

            if(element.id == 10)  {              
              this.crud.form.ID10.id = element.id
              this.crud.form.ID10.value_string = element.value_string
              this.crud.form.ID10.value_string_arr = this.parseStatus(element.value_string)              
            }       
                        
            if(element.id == 11)  {
              this.crud.form.ID11.id = element.id
              this.crud.form.ID11.value_number = parseInt(element.value_number)
            }      
            
            if(element.id == 12)  {              
              this.crud.form.ID12.id = element.id
              this.crud.form.ID12.value_string = element.value_string              
            } 
            
            if(element.id == 13)  {              
              this.crud.form.ID13.id = element.id
              this.crud.form.ID13.value_string = element.value_string              
            }    

            if(element.id == 14)  {
              this.crud.form.ID14.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID14.value_boolean = true
              } else {
                this.crud.form.ID14.value_boolean = false
              }              
            }         

            if(element.id == 15)  {              
              this.crud.form.ID15.id = element.id
              this.crud.form.ID15.value_string = element.value_string              
            } 

            if(element.id == 16)  {
              this.crud.form.ID16.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID16.value_boolean = true
              } else {
                this.crud.form.ID16.value_boolean = false
              }   
            }      
            
            if(element.id == 17)  {
              this.crud.form.ID17.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID17.value_boolean = true
              } else {
                this.crud.form.ID17.value_boolean = false
              }              
            }     
            
            if(element.id == 18)  {
              this.crud.form.ID18.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID18.value_boolean = true
              } else {
                this.crud.form.ID18.value_boolean = false
              }
            } 

            if(element.id == 19)  {
              this.crud.form.ID19.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID19.value_boolean = true
              } else {
                this.crud.form.ID19.value_boolean = false
              }
            } 

            if(element.id == 20)  {
              this.crud.form.ID20.id = element.id
              this.crud.form.ID20.value_string = element.value_string          
            }     

            if(element.id == 21)  {
              this.crud.form.ID21.id = element.id
              this.crud.form.ID21.value_string = (element.value_string ? JSON.parse(element.value_string) : '')
            }     

            if(element.id == 22)  {
              this.crud.form.ID22.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID22.value_boolean = true
              } else {
                this.crud.form.ID22.value_boolean = false
              }
            }         
            
            if(element.id == 24)  {
              this.crud.form.ID24.id = element.id
              if(element.value_boolean) {
                this.crud.form.ID24.value_boolean = true
              } else {
                this.crud.form.ID24.value_boolean = false
              }
            }    
            
            if(element.id == 25)  {
              this.crud.form.ID25.id = element.id
              this.crud.form.ID25.value_string = element.value_string
            }

            if(element.id == 26)  {
              this.crud.form.ID26.id = element.id
              this.crud.form.ID26.value_string = element.value_string
            }
                        
          });          
        })
        .catch(error => {              
          this.$awn.alert(Error.showError(error));
        })        
      },

      parseStatus(item) {
        var status = []
        if(item) {
          status = item.split(',')
        }        
        return status
      },          

      //shipping
      availableShipping() {
        if(!this.crud.form.ID19.value_boolean) {
          this.crud.form.ID18.value_boolean = false
        }        
      },
      requiredShipping() {
        if(this.crud.form.ID18.value_boolean) {
          this.crud.form.ID19.value_boolean = true
        }        
      },

      // save configurations       
      saveRender() {   
        // valido email             
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            let loader = this.$loading.show();
            
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID15);  
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      }, 

      saveNotification() {   
        // valido email     
        if(this.crud.form.ID1 && this.crud.form.ID1.value_string) {
          var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
          if (!expreg.test(this.crud.form.ID1.value_string)) {          
            this.$awn.alert(this.crud.form.ID1.value_string + ": Correo electrónico con formato inválido.")          
            return false
          }    
        }

        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            let loader = this.$loading.show();
            
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID1);     
            result = serviceAPI.saveConfiguracion(this.crud.form.ID6);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID7);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID8);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID25); 
            result = serviceAPI.saveConfiguracion(this.crud.form.ID26); 
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      }, 
      
      saveSituationBCRA() {
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            let loader = this.$loading.show();
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID2);     
            result = serviceAPI.saveConfiguracion(this.crud.form.ID16);     
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })        
      },

      saveProducts() {
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            let loader = this.$loading.show();
            
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID3);           
            result = serviceAPI.saveConfiguracion(this.crud.form.ID4);     
            result = serviceAPI.saveConfiguracion(this.crud.form.ID11);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID17);              
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })            
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })        
      },
      
      saveIntegration() {
        // valido email     
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if(this.crud.form.ID9.value_boolean) {
            if(!this.crud.form.ID5.value_string) {
              this.$awn.alert("No se cargo la URL para la vinculación con el sistema de producción")
              return false            
            }
          }

          if (value) {                     
            let loader = this.$loading.show();

            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID5);     
            result = serviceAPI.saveConfiguracion(this.crud.form.ID9);  
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })        
      },

      saveStatusAlt() {        
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            this.crud.form.ID10.value_string = this.crud.form.ID10.value_string_arr.toString();

            let loader = this.$loading.show();         
          
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID10);                 
            result = serviceAPI.saveConfiguracion(this.crud.form.ID12);                 
            result = serviceAPI.saveConfiguracion(this.crud.form.ID13);                 
            result = serviceAPI.saveConfiguracion(this.crud.form.ID14); 
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })        
      },   
      
      saveOrders() {
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            let loader = this.$loading.show();
            
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID18);   
            result = serviceAPI.saveConfiguracion(this.crud.form.ID19);   
            result = serviceAPI.saveConfiguracion(this.crud.form.ID20); 
            result = serviceAPI.saveConfiguracion(this.crud.form.ID21); 
            result = serviceAPI.saveConfiguracion(this.crud.form.ID22); 
            result = serviceAPI.saveConfiguracion(this.crud.form.ID24); 
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })            
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })        
      },     
    } 
  }
</script>